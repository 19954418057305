<template>
  <div class="signUpInfo_container">
    <top style="padding: 0.2rem 0"></top>
    <!-- 基本信息表 -->
    <div class="info_table doctor_base_info">
      <div class="is_watch" v-if="type == 1"></div>
      <div class="info_table_tr info_table_title">基本信息</div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">医生姓名</div>
        <div class="info_table_td_value">
          <el-input
            v-model.trim="data.name"
            placeholder="请输入医生姓名"
          ></el-input>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">HCP医生编码</div>
        <div class="info_table_td_value">
          <el-input
            v-model.trim="data.code"
            placeholder="请输入HCP医生编码"
          ></el-input>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">省份</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.province"
            placeholder="请选择省份"
            @change="changeValue('province')"
          >
            <el-option
              v-for="(item, index) in provinces"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">城市</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.city"
            placeholder="请选择城市"
            @change="changeValue('city')"
          >
            <el-option
              v-for="(item, index) in citys"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">医院</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.hospital"
            placeholder="请选择医院"
            @change="changeValue('hospital')"
          >
            <el-option
              v-for="(item, index) in config.hospitalList"
              :key="index"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">医院code</div>
        <div class="info_table_td_value">
          {{ data.hospital_code }}
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">科室</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.department"
            placeholder="请选择科室"
            @change="changeValue('department')"
          >
            <el-option
              v-for="(item, index) in config.departmentList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">医生电话</div>
        <div class="info_table_td_value">
          <el-input
            v-model.trim="data.phone"
            placeholder="请输入医生电话"
          ></el-input>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">主TA</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.Ta"
            placeholder="请选择主TA"
          >
            <el-option
              v-for="(item, index) in config.TaList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr" v-if="type == 1">
        <div class="info_table_td_label border_right">主席权益</div>
        <div class="info_table_td_value">
          <el-input v-model.trim="data.cohosts_count"></el-input>
        </div>
      </div>
      <div class="info_table_tr" v-if="type == 1">
        <div class="info_table_td_label border_right">讲者权益</div>
        <div class="info_table_td_value">
          <el-input v-model.trim="data.speaker_count"></el-input>
        </div>
      </div>
      <div class="info_table_tr" v-if="type == 1">
        <div class="info_table_td_label border_right">讨论权益</div>
        <div class="info_table_td_value">
          <el-input v-model.trim="data.guest_count"></el-input>
        </div>
      </div>
    </div>
    <!-- 所属团队信息 -->
    <div class="info_table team_info_table">
      <div class="is_watch" v-if="type == 1"></div>
      <div class="info_table_tr info_table_title">所属团队信息</div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">区域</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.area"
            placeholder="请选择区域"
            @change="changeValue('area')"
          >
            <el-option
              v-for="(item, index) in config.areaList"
              :key="index"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">RMM</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.RMM"
            placeholder="请选择RMM"
            @change="changeValue('RMM')"
          >
            <el-option
              v-for="(item, index) in config.RMMList"
              :key="index"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">RSM(大区经理)</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.RSM"
            placeholder="请选择RSM(大区经理)"
            @change="changeValue('RSM')"
          >
            <el-option
              v-for="(item, index) in config.RSMList"
              :key="index"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">DSM(地区经理)</div>
        <div class="info_table_td_value">
          <el-select
            popper-class="selectOption"
            v-model="data.DSM"
            placeholder="请选择DSM(地区经理)"
            @change="changeValue('DSM')"
          >
            <el-option
              v-for="(item, index) in config.DSMList"
              :key="index"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="info_table_tr">
        <div class="info_table_td_label border_right">医助姓名</div>
        <div class="info_table_td_value">
          <el-input
            v-model.trim="data.sale_name"
            placeholder="请输入医助姓名"
          ></el-input>
        </div>
      </div>
      <div class="info_table_tr" style="border-bottom: none">
        <div class="info_table_td_label border_right">医助电话</div>
        <div class="info_table_td_value">
          <el-input
            v-model.trim="data.sale_phone"
            placeholder="请输入医助电话"
          ></el-input>
        </div>
      </div>
    </div>
    <!-- 审核结果 -->
    <div
      class="info_table_tr"
      style="border-bottom: none"
      v-if="this.type == 1"
    >
      <div class="info_table_td_label" style="justify-content: flex-end">
        <span class="global_tag_require">*</span>审核结果
      </div>
      <div class="info_table_td_value" style="padding-right: 1.65rem">
        <el-select
          popper-class="selectOption"
          v-model="auditData.audit_status"
          placeholder="请选择审核结果"
        >
          <el-option
            v-for="(item, index) in config.auditStatus"
            :key="index"
            :label="item"
            :value="Number(index)"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div
      class="info_table_tr"
      style="border-bottom: none"
      v-if="this.type == 1 && auditData.audit_status == 20"
    >
      <div class="info_table_td_label" style="justify-content: flex-end">
        <span class="global_tag_require">*</span>失败原因
      </div>
      <div class="info_table_td_value" style="padding-right: 1.65rem">
        <el-input
          type="textarea"
          placeholder="请输入审核失败原因"
          v-model="auditData.audit_reason"
        ></el-input>
      </div>
    </div>
    <el-button type="primary" class="audit_button" @click="doCommit(type)">{{
      this.type == 1 ? "审核" : "修改"
    }}</el-button>
  </div>
</template>
  <script>
import top from "./top.vue";
export default {
  name: "signUpInfo",
  components: {
    top,
  },
  data() {
    return {
      data: {
        name: "",
        code: "",
        province: "",
        city: "",
        hospital: "",
        hospital_code: "",
        department: "",
        phone: "",
        Ta: "",
        RMM: "",
        area: "",
        RSM: "",
        DSM: "",
        sale_name: "",
        sale_phone: "",
        audit_status: "",
      },
      config: {
        areaList: [],
        RMMList: [],
        RSMList: [],
        DSMList: [],
        hospitalList: [],
        departmentList: [],
        citys: [],
        TaList: ["LC", "PC", "MM", "BC", "Pan tumor", "Other"],
        auditStatus: {
          // 0: "待审核",
          10: "审核成功",
          20: "审核失败",
        },
      },
      auditData: {
        audit_status: 10,
        id: "",
        audit_reason: "",
      },
      type: "",
    };
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "报名系统";
    this.type = this.$route.query.type;
    this.loadData();
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  methods: {
    loadData() {
      let url = this.$tools.getURL(this.$urls.hospital.detail, {
        id: this.$route.query.id,
      });
      this.$axios.get(url).then((res) => {
        console.log(res);
        this.data = res.data;
        this.auditData.audit_status =
          this.data.audit_status == 0 ? 10 : this.data.audit_status;
        this.auditData.audit_reason = this.data.audit_reason;
        this.initData();
      });
    },
    initData() {
      if (this.data.area) {
        this.getRMM(0, "RMM", true);
      }
      if (this.data.RSM) {
        this.getRMM(this.data.RMM, "RSM", true);
      }
      if (this.data.DSM) {
        this.getRMM(this.data.RSM, "DSM", true);
      }
      this.getHospital();
      this.getAreaList();
    },
    getAreaList() {
      this.$axios
        .get(this.$urls.hospital.areaList)
        .then((res) => {
          this.config.areaList = res.data.map((v) => {
            return { label: v.area };
          });
        })
        .catch(() => {});
    },
    changeValue(key) {
      switch (key) {
        case "area":
          this.getRMM(0, "RMM");
          break;
        case "RMM":
          this.getRMM(this.data[key], "RSM");
          break;
        case "RSM":
          this.getRMM(this.data[key], "DSM");
          break;
        case "province":
          this.changeProvince();
          break;
        case "city":
          this.changeCity();
          break;
        case "hospital":
          this.changeHospital();
          break;
      }
    },
    //获取AMM RSM DSM
    getRMM(e, key, type = false) {
      if (!type) {
        this.data.DSM = "";
        this.config.DSMList = {};
        if (!type && key == "RMM") {
          this.data.RMM = "";
          this.data.RSM = "";
          this.config.RMMList = {};
          this.config.RSMList = {};
        }
        if (!type && key == "RSM") {
          this.data.RSM = "";
          this.config.RSMList = {};
        }
      }

      let url = this.$tools.getURL(this.$urls.hospital.managerList, {
        id: e,
        area: this.data.area,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config[`${key}List`] = res.data.map((v) => {
            return { id: v.id, label: v.name };
          });
        })
        .catch(() => {});
    },
    changeHospital(e) {
      this.config.hospitalList.filter((v) => {
        if (v.label == this.data.hospital) {
          this.data.hospital_code = v.hospital_code;
          this.config.departmentList = v.department;
        }
      });
      if (e) {
        this.data.department = "";
      }
    },
    changeProvince() {
      this.data.city = "";
      this.data.hospital = "";
      this.data.hospital_code = "";
      this.data.department = "";
    },
    changeCity() {
      //筛选医院
      this.data.hospital = "";
      this.data.hospital_code = "";
      this.data.department = "";
      this.getHospital();
    },
    //获取医院
    getHospital() {
      let url = this.$tools.getURL(this.$urls.hospital.hospitalList, {
        province: this.data.province,
        city: this.data.city,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.hospitalList = res.data.map((v) => {
            return {
              label: v.hospital,
              hospital_code: v.hospital_code,
              department: v.department,
            };
          });
          this.changeHospital();
        })
        .catch(() => {});
    },
    checkData() {
      let dict = {
        name: {
          label: "医生姓名",
          type: "input",
        },
        code: {
          label: "HCP医生编码",
          type: "input",
        },
        province: {
          label: "省份",
          type: "select",
        },
        city: {
          label: "城市",
          type: "select",
        },
        hospital: {
          label: "医院",
          type: "select",
          method: true,
        },
        hospital_code: {
          label: "医院code",
          type: "text",
        },
        department: {
          label: "科室",
          type: "select",
          method: true,
        },
        phone: {
          label: "医生电话",
          type: "input",
        },
        Ta: {
          label: "主TA",
          type: "select",
        },
        area: {
          label: "区域",
          type: "select",
        },
        RMM: {
          label: "RMM",
          type: "select",
        },
        RSM: {
          label: "RSM（大区经理）",
          type: "select",
        },
        DSM: {
          label: "DSM（地区经理）",
          type: "select",
        },
        sale_name: {
          label: "医助姓名",
          type: "input",
        },
        sale_phone: {
          label: "医助电话",
          type: "input",
        },
      };
      let data = Object.keys(dict);
      for (let index = 0; index < data.length; index++) {
        const key = data[index];
        switch (key) {
          case "RMM":
          case "area":
          case "RSM":
          case "DSM":
          case "province":
          case "city":
          case "department":
            if (this.data[key] === "") {
              this.$tips.error({ text: `请选择${dict[key].label}` });
              return false;
            }
            break;
          case "sale_name":
          case "name":
          case "Ta":
            if (this.data[key] === "") {
              this.$tips.error({ text: `请输入${dict[key].label}` });
              return false;
            }
            break;
          case "code": //12位长度限制
            if (!this.data[key] && !/^\d{2,12}$/.test(this.data[key]))
              return this.$tips.error({ text: `请输入2-12位HCP医生编码` });

            break;
          case "sale_phone":
          case "phone":
            if (!/^[1]\d{10}$/.test(this.data[key]))
              return this.$tips.error({
                text: `请输入正确的${key == "phone" ? "医生" : "代表"}手机号`,
              });
            break;
        }
      }

      return true;
    },
    doUpdate() {
      if (!this.checkData() || this.loading) return;
      this.loading = true;
      this.currLoading = this.$tips.loading();
      this.$axios
        .patch(this.$urls.hospital.update, this.data)
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.currLoading.close();
            this.$tips.success({ text: "修改成功" });
            this.$router.back();
          }, 1000);
        })
        .catch(() => {
          this.currLoading.close();
          this.loading = false;
        });
    },
    formatAuditData() {
      if (
        this.auditData.audit_status === 20 &&
        this.auditData.audit_reason === ""
      ) {
        this.$tips.error({ text: "请输入审核失败原因" });
        return false;
      }

      return true;
    },
    doAudit() {
      if (!this.formatAuditData() || this.loading) return;
      this.loading = true;
      this.currLoading = this.$tips.loading();
      this.$axios
        .post(this.$urls.hospital.audit, {
          ...this.auditData,
          id: this.data.id,
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.currLoading.close();
            this.$tips.success({ text: "审核成功" });
            this.$router.back();
          }, 1000);
        })
        .catch(() => {
          this.currLoading.close();
          this.loading = false;
        });
    },
    doCommit(type) {
      if (type == 1) {
        //审核
        this.doAudit();
      } else {
        // 修改
        this.doUpdate();
      }
    },
  },
};
</script>
  <style>
.signUpInfo_container {
  width: 100%;
  height: 100%;
  padding: 1.187rem 0.84rem;
  font-size: 0.725rem;
  overflow: auto;
}
.border_left {
  border-left: 1px solid #929292;
}
.border_right {
  border-right: 1px solid #929292;
}
.border_top {
  border-top: 1px solid #929292;
}
.border_bottom {
  border-bottom: 1px solid #929292;
}
.info_table {
  width: 100%;
  /* min-height: 20rem; */
  border: 1px solid #929292;
  margin-bottom: 1.1rem;
  position: relative;
}
.doctor_base_info {
  background-color: #fcf5f5;
}
.team_info_table {
  background-color: #fdf9eb;
}
.info_table_title {
  font-weight: 600;
  padding: 0 0.365rem;
}
.info_table_tr {
  width: 100%;
  min-height: 2rem;
  border-bottom: 1px solid #929292;
  /* padding:0 0.365rem; */
  display: flex;
  align-items: center;
}
.info_table_td_label {
  min-height: 2rem;
  flex: 5.7rem 0 0;
  height: 100%;
  padding: 0 0.365rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.info_table_td_value {
  height: 100%;
  flex: 1 0 0;
  padding: 0.23rem 0.3rem;
  display: flex;
  align-items: center;
}
.info_table_td_value .el-input,
.info_table_td_value .el-select {
  font-size: 0.725rem;
  width: 100%;
  height: 100%;
  line-height: 0;
}
.info_table_td_value .select-trigger {
  height: 100%;
}
.info_table_td_value .el-input__inner {
  height: 100%;
  line-height: 0;
  background-color: transparent;
  padding: 0 0.3rem;
}
.info_table_td_value .el-input__suffix {
  display: flex;
  align-items: center;
}
.audit_button {
  margin-top: 1.3rem;
  width: 100%;
  height: 1.5rem;
}
.audit_button {
  min-height: 1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1e649e;
  border-color: #1e649e;
  --el-button-background-color: #1e649e;
  --el-button-border-color: #1e649e;
  --el-button-hover-color: #1e649e;
  --el-button-active-background-color: #1e649e;
  --el-button-active-border-color: #1e649e;
}
.selectOption .el-select-dropdown__item.selected {
  color: #1e649e !important;
}
.signUpInfo_container .el-select .el-input.is-focus .el-input__inner {
  border-color: #1e649e !important;
}
.signUpInfo_container .el-input__inner:focus {
  border-color: #1e649e !important;
}
.signUpInfo_container .el-textarea__inner {
  padding: 0.3rem !important;
  font-size: 0.725rem !important;
}
.doctor_base_info .info_table_tr:last-child {
  border-bottom: none;
}
.is_watch {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.signUpInfo_container .el-select .el-input.is-focus .el-input__wrapper,
.signUpInfo_container .el-input__wrapper:hover {
  box-shadow: 0 0 0 1px #1e649e inset !important;
}
</style>